import React, { useState } from "react";
import Seo from "../../components/_App/seo";
import Navbar from "../../components/_App/Navbar";
import Layout from "../../components/_App/layout";
import Breadcrumbs from "../../components/Common/Breadcrumbs";
import Partner from "../../components/Common/Partner";
import MilestonesAchievedSoFar from "../../components/Common/MilestonesAchievedSoFar";
import Footer from "../../components/_App/Footer";
import { FaLink } from "react-icons/fa"
import { FiFileText } from "react-icons/fi"
import { BiSolidContact, BiSolidPhoneCall } from "react-icons/bi"
import { FaCommentSms, FaLocationDot } from "react-icons/fa6"
import { SlCalender } from "react-icons/sl"
import { IoMdMail } from "react-icons/io"
import { IoWifiSharp } from "react-icons/io5"
import VCardQrCode from "../../components/QRCode/QRCodeTabs/VCardQRCode"
import WifiQrCode from "../../components/QRCode/QRCodeTabs/WifiQRCode"
import UrlQrCode from "../../components/QRCode/QRCodeTabs/UrlQRCode"
import TextQrCode from "../../components/QRCode/QRCodeTabs/TextQRCode"
import MailQRCode from "../../components/QRCode/QRCodeTabs/MailQRCode"
import SMSTypeQRCode from "../../components/QRCode/QRCodeTabs/SMSTypeQRCode"
import LocationQRCode from "../../components/QRCode/QRCodeTabs/LocationQRCode"
import ContactQrCode from "../../components/QRCode/QRCodeTabs/ContactQRCode"
import CalenderQRCode from "../../components/QRCode/QRCodeTabs/CalenderQRCode"

const parent = [
  {
    name: "Tools",
    link: "tools",
  },
];

const qrCodeTypes = [
  { value: "URL", icon: <FaLink size={18} /> },
  { value: "Text", icon: <FiFileText size={18} /> },
  { value: "vCard", icon: <BiSolidContact size={18} /> },
  { value: "SMS", icon: <FaCommentSms size={18} /> },
  { value: "Call", icon: <BiSolidPhoneCall size={18} /> },
  { value: "Location", icon: <FaLocationDot size={18} /> },
  { value: "Event", icon: <SlCalender size={18} /> },
  { value: "Mail", icon: <IoMdMail size={18} /> },
  { value: "Wifi", icon: <IoWifiSharp size={18} /> },
];

function QrCode() {
  const [selectedOption, setSelectedOption] = useState("URL");

  const handleClick = (value) => {
    setSelectedOption(value);
  };

  const downloadQR = () => {
    const canvas = document.getElementById("qr");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    const downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = "qr.png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const renderQRCodeComponent = () => {
    switch (selectedOption) {
      case "vCard":
        return <VCardQrCode downloadQR={downloadQR} />;
      case "Wifi":
        return <WifiQrCode downloadQR={downloadQR} />;
      case "URL":
        return <UrlQrCode downloadQR={downloadQR} />;
      case "Text":
        return <TextQrCode downloadQR={downloadQR} />;
      case "Mail":
        return <MailQRCode downloadQR={downloadQR} />;
      case "SMS":
        return <SMSTypeQRCode downloadQR={downloadQR} />;
      case "Location":
        return <LocationQRCode downloadQR={downloadQR} />;
      case "Event":
        return <CalenderQRCode downloadQR={downloadQR} />;
      case "Call":
        return <ContactQrCode downloadQR={downloadQR} />;
      default:
        return null;
    }
  };

  return (
    <Layout>
      <Seo title="QR Code Generator Tool" />
      <Navbar />
      <div className="contact-area ptb-80">
        <div className="container py-5">
          <div className="section-title">
            <h1>QR Code Generator</h1>
            <div className="bar" />
          </div>
          <Breadcrumbs pageTitle="QR Code Generator" parent={parent} />
          <div className="row align-items-center flex-wrap">
            <div className="form-group col-lg-12">
              <label htmlFor="componentSelector">Choose QR code Type:</label>
              <div className="d-flex gap-2 align-items-center mb-3 flex-wrap">
                {qrCodeTypes.map((type) => (
                  <div
                    key={type.value}
                    className={`btn d-flex align-items-center py-2 px-3 ${type.value === selectedOption ? 'btn-primary' : 'qrType-inactive-btn'}`}
                    onClick={() => handleClick(type.value)}
                  >
                    {type.icon}
                    <button
                      style={{
                        border: "none",
                        backgroundColor: "transparent",
                        fontSize: 16,
                        color: "inherit",
                        fontWeight: 600,
                        letterSpacing: "0.6px",
                      }}
                    >
                      {type.value}
                    </button>
                  </div>
                ))}

              </div>
            </div>
            {renderQRCodeComponent()}
          </div>
        </div>
      </div>
      <Partner />
      <MilestonesAchievedSoFar />
      <Footer />
    </Layout>
  );
}

export default QrCode;
