import React, { useState } from "react"
import * as convertStyled from "../../../assets/css/convert-case.module.css"
import QRCode from "qrcode.react"

function SMSTypeQRCode({ downloadQR }) {
  const [smsInfo, setSmsInfo] = useState({
    contact: "",
    message: ""
  })

  const [qrData, setQrData] = useState('')

  const handleChange = (event) => {
    const { name, value } = event.target
    setSmsInfo((prevInfo) => ({
      ...prevInfo,
      [name]: value
    }))
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    const smsData = `SMSTO:${smsInfo.contact}:${smsInfo.message}`

    setQrData(smsData)

    const qrCodeUrl = `https://api.qrserver.com/v1/create-qr-code/?data=${encodeURIComponent(
      qrData
    )}`

  }

  const isInputEmpty = smsInfo.contact === "" && smsInfo.message === "";

  return (
    <div>
      <div className="row align-items-center">
        <div className="row">
          <div className="col-lg-6">
            <div className="form-group">
              <label htmlFor="url">contact:</label>
              <input
                type="text"
                className="form-control"
                id="contact"
                name="contact"
                value={smsInfo.contact}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="message">Text:</label>
              <textarea
                rows='6'
                cols='30'
                className="form-control"
                id="message"
                name="message"
                value={smsInfo.message}
                onChange={handleChange}
                required
              />
            </div>

            <div className='d-flex '>
              <button
                className={`${convertStyled.download_copy_btn} btn text-white btn-primary mt-3`}
                onClick={handleSubmit} disabled={isInputEmpty}
              >
                Generate QR Code
              </button>
              <button
                className={`${convertStyled.download_copy_btn} btn text-white btn-primary mt-3 mx-3`}
                onClick={downloadQR} disabled={!qrData}
              >
                Download QR Code
              </button>
            </div>
          </div>
          <div className="col-lg-6 mt-lg-0 mt-5">
            <div className="text-center" style={{border: "2px solid black", borderRadius:"8px"}}>
              {!qrData ?  <p style={{height:300, paddingTop:130, fontSize: 20}}>Please enter valid SMS details to generate QR Code</p> : <QRCode id="qr" includeMargin={true} className="qr-code" value={qrData} size={300} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SMSTypeQRCode