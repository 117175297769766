import React, { useState } from "react"
import * as convertStyled from "../../../assets/css/convert-case.module.css"
import QRCode from "qrcode.react"

function ContactQrCode({ downloadQR }) {
  const [contact, setContact] = useState('')
  const [qrData, setQrData] = useState("")

  const handleSubmit = (e) => {
    e.preventDefault()

    setQrData(contact)
  }

  return (
    <div>
      <div className="row align-items-center">
        <div className="row">
          <div className="col-lg-6">
            <form onSubmit={handleSubmit} noValidate className='needs-validation'>
              <div className="form-group">
                <label htmlFor="contact">Contact:</label>
                <input
                  type="tel"
                  className={`form-control`}
                  id="contact"
                  name="contact"
                  value={contact}
                  onChange={(e) => setContact(e.target.value)}
                  required
                />
              </div>

              <div className='d-flex'>
                <button
                  className={`${convertStyled.download_copy_btn} btn text-white btn-primary mt-3`}
                  type='submit' disabled={!contact}
                >
                  Generate QR Code
                </button>
                <button
                  className={`${convertStyled.download_copy_btn} btn text-white btn-primary mt-3 mx-3`}
                  onClick={downloadQR} disabled={!qrData}
                >
                  Download QR Code
                </button>
              </div>
            </form>
          </div>
          <div className="col-lg-6 mt-lg-0 mt-5">
            <div className="text-center" style={{border: "2px solid black", borderRadius:"8px"}}>
              {!qrData ? <p style={{height:300, paddingTop:130, fontSize: 18}}>Please enter valid Contact Number to generate QR Code</p> : <QRCode id="qr" includeMargin={true} className="qr-code" value={`TEL:${qrData}`} size={300} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactQrCode