import React, { useState } from "react"
import * as convertStyled from "../../../assets/css/convert-case.module.css"
import QRCode from "qrcode.react"

function TextQrCode({ downloadQR }) {
  const [text, setText] = useState('')

  const handleChange = (event) => {
    setText(event.target.value)
  }

  return (
    <div>
      <div className="row align-items-center">
        <div className="row">
          <div className="col-lg-6">
            <div className="form-group">
              <label htmlFor="text">Text:</label>
              <textarea
                rows='6'
                cols='30'
                className="form-control"
                id="text"
                name="text"
                value={text}
                onChange={handleChange}
                required
              />
            </div>


            <button
              className={`${convertStyled.download_copy_btn} btn text-white btn-primary mt-3`}
              onClick={downloadQR} disabled={!text}
            >
              Download QR Code
            </button>
          </div>
          <div className="col-lg-6 mt-lg-0 mt-5">
            <div className="text-center" style={{border: "2px solid black", borderRadius:"8px"}}>
              {!text ? <p style={{height:300, paddingTop:130, fontSize: 20}}>Please enter valid Text to generate QR Code</p> :<QRCode  id='qr' includeMargin={true} className="qr-code" value={text} size={300}/>}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TextQrCode