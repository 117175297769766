import React, { useState } from "react"
import * as convertStyled from "../../../assets/css/convert-case.module.css"
import QRCode from "qrcode.react"

function MailQRCode({ downloadQR }) {
  const [mailInfo, setMailInfo] = useState({
    to: "",
    subject: "",
    body: ""
  })

  const [qrData, setQrData] = useState('')

  const handleChange = (event) => {
    const { name, value } = event.target
    setMailInfo((prevInfo) => ({
      ...prevInfo,
      [name]: value
    }))
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    const mailData = `MATMSG:TO:${mailInfo.to};SUB:${mailInfo.subject};BODY:${mailInfo.body};;`

    setQrData(mailData)

    const qrCodeUrl = `https://api.qrserver.com/v1/create-qr-code/?data=${encodeURIComponent(
      qrData
    )}`

  }

  const isInputEmpty = mailInfo.to === "" || mailInfo.subject === "" || mailInfo.body === "";

  return (
    <div>
      <div className="row align-items-center">
        <div className="row">
          <div className="col-lg-6">
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="to">to:</label>
                <input
                  type="email"
                  className="form-control"
                  id="to"
                  name="to"
                  value={mailInfo.to}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="subject">subject:</label>
                <input
                  type="text"
                  className="form-control"
                  id="subject"
                  name="subject"
                  value={mailInfo.subject}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="body">body:</label>
                <input
                  type="text"
                  className="form-control"
                  id="body"
                  name="body"
                  value={mailInfo.body}
                  onChange={handleChange}
                  required
                />
              </div>


              <div className='d-flex'>
                <button
                  className={`${convertStyled.download_copy_btn} btn text-white btn-primary mt-3`}
                  type='submit' disabled={isInputEmpty}
                >
                  Generate QR Code
                </button>
                <button
                  className={`${convertStyled.download_copy_btn} btn text-white btn-primary mt-3 mx-3`}
                  onClick={downloadQR} disabled={!qrData}
                >
                  Download QR Code
                </button>
              </div>
            </form>
          </div>
          <div className="col-lg-6 mt-lg-0 mt-5">
            <div className="text-center" style={{border: "2px solid black", borderRadius:"8px"}}>
              {!qrData ?  <p style={{height:300, paddingTop:130, fontSize: 20}}>Please enter Mail details to generate QR Code</p>: <QRCode id="qr" includeMargin={true} className="qr-code" value={qrData} size={300} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MailQRCode