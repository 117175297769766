import React, { useState } from "react"
import * as convertStyled from "../../../assets/css/convert-case.module.css"
import QRCode from "qrcode.react"

function CalenderQRCode({ downloadQR }) {
  const [title, setTitle] = useState("")
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")

  const [qrData, setQrData] = useState("")
  const [error, setError] = useState("")

  const handleSubmit = (event) => {
    event.preventDefault()

    if (new Date(startDate) > new Date(endDate)) {
      setError("Start Date should be equal or before the End Date")
      return;
    }

    const dateStartFormatted = startDate.replace(/-/g, "")
    const dateEndFormatted = endDate.replace(/-/g, "")

    const calenderData = `BEGIN:VCALENDAR\nBEGIN:VEVENT\nDTSTART:${dateStartFormatted}\nDTEND:${dateEndFormatted}\nSUMMARY:${title}\nEND:VEVENT\nEND:VCALENDAR`

    setQrData(calenderData)

    const qrCodeUrl = `https://api.qrserver.com/v1/create-qr-code/?data=${encodeURIComponent(
      qrData
    )}`

    setError("");
  }

  const isInputEmpty = title === "" || startDate === "" || endDate === "";

  return (
    <div>
      <div className="row align-items-center">
        <div className="row">
          <div className="col-lg-6">
            <div className="form-group">
              <label htmlFor="title">title:</label>
              <input
                type="text"
                className="form-control"
                id="title"
                name="title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="startDate">Start Date:</label>
              <input
                type="date"
                className="form-control"
                id="startDate"
                name="startDate"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="endDate">End Date:</label>
              <input
                type="date"
                className="form-control"
                id="endDate"
                name="endDate"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                required
              />
            </div>
            {error && <p style={{ color: "red" }}>{error}</p>}


            <div className='d-flex'>
              <button
                className={`${convertStyled.download_copy_btn} btn text-white btn-primary mt-3`}
                onClick={handleSubmit} disabled={isInputEmpty}
              >
                Generate QR Code
              </button>
              <button
                className={`${convertStyled.download_copy_btn} btn text-white btn-primary mt-3 mx-3`}
                onClick={downloadQR} disabled={!qrData}
              >
                Download QR Code
              </button>
            </div>
          </div>
          <div className="col-lg-6 mt-lg-0 mt-5">
            <div className="text-center" style={{border: "2px solid black", borderRadius:"8px"}}>
              {!qrData?  <p style={{height:300, paddingTop:130, fontSize: 20}}>Please enter Event Details to generate QR Code</p> :<QRCode id="qr" includeMargin={true} className="qr-code" value={qrData} size={300} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CalenderQRCode