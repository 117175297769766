import React, { useEffect, useState } from "react"
import * as convertStyled from "../../../assets/css/convert-case.module.css"
import QRCode from "qrcode.react"
import { Spinner } from "react-bootstrap"

function LocationQRCode({ downloadQR }) {
  const [lat, setLat] = useState("")
  const [long, setLong] = useState("")
  const [qrData, setQrData] = useState("")
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getCurrentLocation()
  }, [])



  function getCurrentLocation () {
    setLoading(true);
    navigator.geolocation.getCurrentPosition((pos) => {
      setLat(pos.coords.latitude);
      setLong(pos.coords.longitude);
      setLoading(false)
    }, (err) => {
      alert(err.message);
      setLoading(false)
    }, {
      enableHighAccuracy: true
    });
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    setQrData('http://maps.google.com/maps?q=' + lat + ','+ long)

    const qrCodeUrl = `https://api.qrserver.com/v1/create-qr-code/?data=${encodeURIComponent(
      qrData
    )}`
  }


  return (
    <div>
      <div className="row align-items-center">
        <div className="row">
          <div className="col-lg-6">
            <div className="form-group">
              <label htmlFor="lat">Latitude:</label>
              <input
                type="text"
                className="form-control"
                id="lat"
                name="lat"
                value={lat}
                onChange={(e) => setLat(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="long">Longitude:</label>
              <input
                type="text"
                className="form-control"
                id="long"
                name="long"
                value={long}
                onChange={(e)=> setLong(e.target.value)}
                required
              />
            </div>

            <div className='d-flex'>
              <button
                className={`${convertStyled.download_copy_btn} btn text-white btn-primary mt-3`}
                onClick={handleSubmit} disabled={!lat && !long}
              >
                Generate QR Code
              </button>
              <button
                className={`${convertStyled.download_copy_btn} btn text-white btn-primary mt-3 mx-3`}
                onClick={downloadQR} disabled={!lat && !long}
              >
                Download QR Code
              </button>
            </div>
          </div>
          <div className="col-lg-6 mt-lg-0 mt-5">
            <div className="text-center" style={{border: "2px solid black", borderRadius:"8px"}}>
              {!qrData ?  <p style={{height:300, paddingTop:130, fontSize: 20}}>Please enter Location details to generate QR Code</p> : <QRCode id="qr" includeMargin={true} className="qr-code" value={qrData} size={300} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LocationQRCode