import React, { useState } from "react"
import * as convertStyled from "../../../assets/css/convert-case.module.css"
import QRCode from "qrcode.react"

function WifiQrCode({ downloadQR }) {
  const [wifiInfo, setWifiInfo] = useState({
    ssid: "",
    password: ""
  })
  const [selectedType, setSelectedType] = useState("WPA")
  const [qrData, setQrData] = useState('')
  const encryptionOptions = ["WPA", "WEP"]

  function handleSelectOption(e) {
    setSelectedType(e.target.value)
  }

  const handleChange = (event) => {
    const { name, value } = event.target
    setWifiInfo((prevInfo) => ({
      ...prevInfo,
      [name]: value
    }))
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    const wifiData = `WIFI:T:${selectedType};S:${wifiInfo.ssid};P:${wifiInfo.password};;`

    setQrData(wifiData)
    const qrCodeUrl = `https://api.qrserver.com/v1/create-qr-code/?data=${encodeURIComponent(
      qrData
    )}`
  }

  const isInputEmpty = wifiInfo.ssid === "" || wifiInfo.password === "" ;

  return (
    <div>
      <div className="row align-items-center">
        <div className="row">
          <div className="col-lg-6">
            <div className="form-group">
              <label htmlFor="ssid">SSID:</label>
              <input
                type="text"
                className="form-control"
                id="ssid"
                name="ssid"
                value={wifiInfo.ssid}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="company">Password:</label>
              <input
                type="text"
                className="form-control"
                id="password"
                name="password"
                value={wifiInfo.password}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="encryption">Encryption:</label>
              <select className="form-select" aria-label="Default select example" onChange={handleSelectOption}>
                {
                  encryptionOptions.map((item) => {
                    return (<option value={item}>{item}</option>)
                  })
                }
              </select>
            </div>


            <div className='d-flex'>
              <button
                className={`${convertStyled.download_copy_btn} btn text-white btn-primary mt-3`}
                onClick={handleSubmit} disabled={isInputEmpty}
              >
                Generate QR Code
              </button>
              <button
                className={`${convertStyled.download_copy_btn} btn text-white btn-primary mt-3 mx-3`}
                onClick={downloadQR} disabled={!qrData}
              >
                Download QR Code
              </button>
            </div>
          </div>
          <div className="col-lg-6 mt-lg-0 mt-5">
              <div className="text-center" style={{border: "2px solid black", borderRadius:"8px"}}>
                {!qrData ?  <p style={{height:300, paddingTop:130, fontSize: 20}}>Please enter Wifi details to generate QR Code</p> : <QRCode id="qr" includeMargin={true} className="qr-code" value={qrData} size={300} />}
              </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WifiQrCode