import React, { useState } from "react"
import * as convertStyled from "../../../assets/css/convert-case.module.css"
import QRCode from "qrcode.react"

function VCardQrCode({ downloadQR }) {
  const [contactInfo, setContactInfo] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    company: "",
    website: "",
    street: "",
    city: "",
    state: "",
    postCode: null,
    country: ""
  })

  const [qrData, setQrData] = useState("")

  const handleChange = (event) => {
    const { name, value } = event.target
    setContactInfo((prevInfo) => ({
      ...prevInfo,
      [name]: value
    }))
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    const vCardData = `BEGIN:VCARD
VERSION:3.0
N:${contactInfo.lastName}; ${contactInfo.firstName}
TEL:${contactInfo.phoneNumber}
EMAIL:${contactInfo.email}
ORG:${contactInfo.company}
ADR:;;${contactInfo.street};${contactInfo.city};${contactInfo.state};${contactInfo.postCode};${contactInfo.country}
URL:${contactInfo.website}
END:VCARD`

    setQrData(vCardData)
    const qrCodeUrl = `https://api.qrserver.com/v1/create-qr-code/?data=${encodeURIComponent(
      qrData
    )}`
  }

  const isInputEmpty = contactInfo.firstName === "" && contactInfo.lastName === "" && contactInfo.email === "" && contactInfo.phoneNumber === "" && contactInfo.company === "" && contactInfo.website === "" && contactInfo.street === "" && contactInfo.city === "" && contactInfo.state === "" && contactInfo.postCode === null && contactInfo.country === ""

  return (
    <form onSubmit={handleSubmit} >
      <div className='d-flex flex-column flex-md-row align-items-start gap-4'>
      <div className="row align-items-center" style={{width: "70%"}}>

        <div className="row">
          <div className='col-lg-4'>
            <div className="form-group">
              <label htmlFor="firstName">First Name:</label>
              <input
                type="text"
                className="form-control"
                id="firstName"
                name="firstName"
                value={contactInfo.firstName}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className='col-lg-4'>
            <div className="form-group">
              <label htmlFor="lastName">Last Name:</label>
              <input
                type="text"
                className="form-control"
                id="lastName"
                name="lastName"
                value={contactInfo.lastName}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className='col-lg-4'>
            <div className="form-group">
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                className="form-control"
                id="email"
                name="email"
                value={contactInfo.email}
                onChange={handleChange}
              />
            </div>
          </div>


          <div className='col-lg-4'>
            <div className="form-group">
              <label htmlFor="phoneNumber">Phone Number:</label>
              <input
                type="tel"
                className="form-control"
                id="phoneNumber"
                name="phoneNumber"
                value={contactInfo.phoneNumber}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className='col-lg-4'>
            <div className="form-group">
              <label htmlFor="company">Company:</label>
              <input
                type="text"
                className="form-control"
                id="company"
                name="company"
                value={contactInfo.company}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className='col-lg-4'>
            <div className="form-group">
              <label htmlFor="website">Website:</label>
              <input
                type="url"
                className="form-control"
                id="website"
                name="website"
                value={contactInfo.website}
                onChange={handleChange}
              />
            </div>
          </div>


          <div className='col-lg-4'>
            <div className="form-group">
              <label htmlFor="street">Street:</label>
              <input
                type="text"
                className="form-control"
                id="street"
                name="street"
                value={contactInfo.street}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className='col-lg-4'>
            <div className="form-group">
              <label htmlFor="city">City:</label>
              <input
                type="text"
                className="form-control"
                id="city"
                name="city"
                value={contactInfo.city}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className='col-lg-4'>
            <div className="form-group">
              <label htmlFor="state">State:</label>
              <input
                type="text"
                className="form-control"
                id="state"
                name="state"
                value={contactInfo.state}
                onChange={handleChange}
              />
            </div>
          </div>


          <div className='col-lg-4'>
            <div className="form-group">
              <label htmlFor="city">Postal Code:</label>
              <input
                type="number"
                className="form-control"
                id="postCode"
                name="postCode"
                value={contactInfo.postCode}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className='col-lg-4'>
            <div className="form-group">
              <label htmlFor="city">Country:</label>
              <input
                type="text"
                className="form-control"
                id="country"
                name="country"
                value={contactInfo.country}
                onChange={handleChange}
              />
            </div>
          </div>


          <div className="col-lg-9 mt-3 d-flex ">
            <button
              className={`${convertStyled.download_copy_btn} btn text-white btn-primary`}
              type="submit" disabled={isInputEmpty}
            >
              Generate QR Code
            </button>
            <button
              className={`${convertStyled.download_copy_btn} btn text-white btn-primary mx-3`}
              onClick={downloadQR} disabled={!qrData}
            >
              Download QR Code
            </button>
          </div>
        </div>
      </div>
        <div style={{width: "30%"}} className='w-75 md-w-0'>
          <div className="text-center" style={{ border: "2px solid black", borderRadius: "8px" }}>
            {!qrData ?
              <p style={{ height: 300, fontSize: 16, padding: "120px 20px" }}>Please enter valid Card data to generate QR
                Code</p> :
              <QRCode id="qr" includeMargin={true} className="qr-code" value={`BEGIN:VCARD\n${qrData}\nEND:VCARD`}
                      size={300} />}
          </div>
        </div>
      </div>
    </form>
  )
}

export default VCardQrCode