import React, { useState } from "react"
import QRCode from "qrcode.react"

function UrlQrCode({ downloadQR }) {
  const [urlInfo, setUrlInfo] = useState("");
  const [qrData, setQrData] = useState("");
  const [urlValidity, setUrlValidity] = useState(true);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (e.target.checkValidity()) {
      setQrData(urlInfo);
      setUrlValidity(true);
    } else {
      setUrlValidity(false);
    }
  };

  return (
    <div>
      <div className="row align-items-center">
        <div className="row">
          <div className="col-lg-6">
            <form onSubmit={handleSubmit} className='needs-validation' noValidate>
              <div>
                <label htmlFor="url">Website URL:</label>
                <input
                  maxLength={2900}
                  type="url"
                  className={`form-control ${urlValidity ? "" : "is-invalid"}`}
                  id="url"
                  name="url"
                  value={urlInfo}
                  onChange={(e) => { setUrlInfo(e.target.value); setUrlValidity(true);}}
                  required
                />
                <div className="invalid-feedback">
                  Please provide a valid URL.
                </div>
              </div>

              <div className='d-flex'>
                <button
                  className='btn btn-primary py-2 px-3 mt-5'
                  type="submit"
                >
                  Generate QR Code
                </button>

                <button
                  className='btn btn-primary py-2 px-3 mx-3 mt-5'
                  onClick={() => downloadQR()} disabled={!qrData}
                >
                  Download QR Code
                </button>
              </div>
            </form>
          </div>
          <div className="col-lg-6 mt-lg-0 mt-5">
            <div className="text-center" style={{ border: "2px solid black", borderRadius: "8px" }}>
              {!qrData ?
                <p style={{ height: 300, paddingTop: 130, fontSize: 20 }}> Please enter URL to generate QR Code </p>
                : <QRCode id="qr" includeMargin={true} className="qr-code" value={qrData} size={300} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UrlQrCode;
